<template>
  <div>
    <Modal class="pop-payment-success" footer-hide v-model="show" v-if="payment.id > 0">
        <div class="check-icon">
          <img src="@/assets/payment-success.png" />
        </div>
      <div class="sub-title">
        Payment Success!
      </div>
      <div class="title">USD 1,000.00</div>
      <div class="sub-title-line"></div>
      <div class="items">
        <div class="item">
          <div class="item-sub">Payment Time</div>
          <div class="item-title">{{payment.created_at | utcTime}}</div>
        </div>
        <div class="item">
          <div class="item-sub">Package</div>
          <div class="item-title">Unlimited</div>
        </div>
        <div class="item">
          <div class="item-sub">Valid From</div>
          <div class="item-title">{{payment.start_date | utcTime}}</div>
        </div>
        <div class="item">
          <div class="item-sub">Valid To</div>
          <div class="item-title">{{payment.end_date | utcTime}}</div>
        </div>

      </div>
    </Modal>
  </div>
</template>

<script>
  import moment from 'moment'

export default {
  filters: {
    utcTime(time) {
      return moment.utc(time).local().format('MM/DD/YYYY HH:mm') // .utcOffset(8)
    }
  },
  props: ['payment'],
  data() {
    return {
      show: false,

    };
  },
  mounted() {},
  methods: {
  }
};
</script>
<style lang="less" scoped>
  .pop-payment-success{
    .check-icon{
      display: flex;
      justify-content: center;
      img{
        width: 60px;
        height: 60px;
      }
    }
    .title{
      font-weight: 600;
      font-size: 30px;
      line-height: 35px;
      /* identical to box height, or 140% */

      text-align: center;

      color: #121212;
      display: flex;
      justify-content: center;

    }
    .sub-title{
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      /* identical to box height, or 157% */

      text-align: center;

      color: #474747;
      display: flex;
      justify-content: center;
      margin: 15px auto 10px auto;
    }

    .sub-title-line{
      width: 494.62px;
      height: 0px;

      border: 1.66539px solid #EDEDED;
      display: flex;
      justify-content: center;
      margin: 20px auto 0 auto;

    }
    .items{
      display: flex;
      flex-flow: row wrap;
      width: 450px;
      justify-content: space-between;
      margin: 0 auto;
      .item{
        /*display: flex;*/

        border: 1.5px solid #d8d8d8;
        width: 220px;
        border-radius: 10px;
        padding: 10px;
        margin-top: 20px;
        .item-sub{
          font-weight: 400;
          font-size: 14px;
          line-height: 27px;
          color: #474747;
        }
        .item-title{
          font-weight: 500;
          font-size: 16px;
          line-height: 30px;
          color: #121212;
        }
      }
    }

  }
  @media (max-width: 800px) {
    .pop-payment-success{
      .sub-title-line{
        width: 100%;
      }
      .title{
        font-size: 25px;
      }
      .sub-title{
        font-size: 16px;
      }
      .items{
        display: block;
        width: 100%;
        .item{
          width: 100%;
          padding: 10px 30px;
          .item-sub{
            display: flex;
            justify-content: center;
          }
          .item-title{
            display: flex;
            justify-content: center;
          }
        }
      }
    }

  }
</style>

<style lang="less">
.pop-payment-success {
  .ivu-modal {
    width: 540px !important;
    .ivu-modal-body{
      padding-bottom: 30px;
    }
  }
}
@media (max-width: 800px) {
  .pop-payment-success {
    .ivu-modal {
      width: 90% !important;
      margin: 0 auto;
    }
  }
}
</style>
